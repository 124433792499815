body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-slider {
  background-image: url("./assets/food-back.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 565px;
  width: 100%;
  background-position: center;
  text-align: center; }
  .image-slider > p {
    position: relative;
    top: 55%;
    font-size: 3em;
    font-family: unset;
    color: #ffff;
  }

