.doordash{
  background: #e95555 !important;
}
.uber{
  background: #08ca29 !important;
  color: #000000 !important;
}
.grubhub{
  background: orange !important;
}

.image-slider-container {
    height: 560px; }
  
  .image-slider {
    background-image: url("../../assets/food-back.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 615px;
    width: 100%;
    background-position: center;
    text-align: center;
    overflow: hidden; }
  
  .pizza-box {
    position: relative;
    top: 40%;
    padding: 5px; }
  
  .our-hours-in-text {
    max-width: 550px;
    margin: auto;
    text-align: center; }

  .hours {
    width: 90%;
    color: #fff;
    text-transform: capitalize;    
  }
  
  .are-we-open {
    font-size: 30px;
    padding: 10px; }
  
  .pizza-animation {
    -webkit-animation: spin 7000ms linear infinite;
    -moz-animation: spin 7000ms linear infinite;
    animation: spin 7000ms linear infinite;
    height: 275px; }
    @media screen and (max-width: 750px) {
      .pizza-animation {
        height: 200px; } }
  
  .slide {
    position: absolute;
    color: #fff;
    animation-duration: 15s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    pointer-events: none;
    margin: auto;
    left: 0;
    right: 210px;
    width: 30%; }
    @media screen and (max-width: 750px) {
      .slide {
        right: 0;
        width: 90%; } }
  
  .slide-0 {
    animation-name: slide1; }
  
  .slide-1 {
    animation-name: slide2;
    font-size: 23px; }
  
  .slide-2 {
    animation-name: slide3; }
  
  .home-intro {
    display: inline-block;
    width: 35%;
    vertical-align: top; }
    @media screen and (max-width: 750px) {
      .home-intro {
        width: 70% !important; } }
  
  .home-intro-text {
    padding: 20px;
    font-size: 25px;
    color: #ffffff;
    height: 50px; 
    margin-top: 60px;
  }
  
  .other {
    background-color: #252322;
    text-align: center;
    background-image: linear-gradient(#252322 55%, #382E2A);
    overflow: hidden; }
  
  .location-in-text {
    color: #fff;
    font-size: 30px;
    display: inline-block;
    width: 35%;
    min-width: 250px;
    padding: 15px;
    user-select: text; }
  
  .were-in-aurora-title {
    font-family: Goldleaf;    
    font-size: 35px;
    color: #fff;
    padding: 45px;
    border-radius: 8px; }
  
  #map {
    text-align: center; }
  
  .google-map {
    display: inline-block;
    vertical-align: middle; }
  
  .user-options {
    text-align: center; }
  
  #map {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .order-online-container, .reviews {
    vertical-align: top;
    max-width: 325px;
    display: inline-block;
    margin: 15px; }
  
  .order-title, .review-title {
    font-size: 45px;
    color: #ffffff;
    border-bottom: 1px solid; }
  
  .online-order-image {
    margin: 10px; }
  
  .middle-align {
    padding: 25px;
    background: #3a3938; }
  
  .grubhub-image {
    padding: 15px;
    display: inline-block; 
  }
  
  .phone-hyper-link {
    display: inline-block;
    background: #ffc20f;
    width: 100%;
  }
  
  .phone-number {
    text-decoration: underline; 
    color: hsl(0, 0%, 0%);
  }

  .orderOnlineMenuContainer{
    background: rgb(37 35 34);
    text-align: center;
    padding: 10px;
    color: rgb(255, 255, 255);
    font-family: 'Goldleaf';
    font-size: 22px;
  }
  .orderOnlineMenu{
    color: #070707;
    margin: 15px 20px 0px 20px;
    background: burlywood;
    padding: 10px;
    font-size: 40px;
    display: inline-block;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(50deg); }
    50% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(50deg); } }
  
  @keyframes slide1 {
    0%, 13% {
      left: -100%;
      opacity: 0; }
    13%, 18% {
      left: 0%;
      opacity: 1; }
    61.33%, 100% {
      left: -50%;
      opacity: 0; } }
  
  @keyframes slide2 {
    0%, 33.33% {
      left: -100%;
      opacity: 0; }
    41.63%, 60% {
      left: 0%;
      opacity: 1; }
    66.66%, 100% {
      left: -50%;
      opacity: 0; } }
  
  @keyframes slide3 {
    0%, 66.66% {
      left: -100%;
      opacity: 0; }
    74.96%, 91.62% {
      left: 0%;
      opacity: 1; }
    100% {
      left: -50%;
      opacity: 0; } 
    }
  