.header{
    width: 100%;
    text-align: center; 
    z-index: 100;
    background: #252322;
}
.ul-container {
    margin: 20px; 
}
.header-container {
    padding: 15px; 
}
.header-nav-container {
    vertical-align: top; 
}
.logo-container, .header-nav-container {
    display: inline-block;
    width: 50%;
    vertical-align: middle; 
}
.logo {
    height: 160px; 
}
.nav-options {
    display: inline-block; 
}
  
.nav-links {
    font-family: GoldLeaf;
    color: #fff;
    margin: 15px;
    text-decoration: none;
    border-bottom: 3px solid #fff; 
}

@media screen and (max-width: 750px) {
    .logo-container, .header-nav-container {
        width: 100%; 
    } 

}
@media screen and (max-width: 550px) {
    .logo-container img{
        width: 100%; 
    }
    .home-intro-text{
        margin-top: 30px;
    }
    .pizza-box{
       top: 50%; 
    }
}