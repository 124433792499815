.image-slider-container {
    height: 560px; }
  
  .image-slider {
    background-image: url("../../assets/food-back.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 565px;
    width: 100%;
    background-position: center;
    text-align: center; }
  
  .about-us-header {
    position: relative;
    top: 50%;
    display: inline-block;
    max-width: 410px;
    font-size: 24px;
    padding: 15px; }
  
  .attention-grabber {
    color: #fff;
    text-transform: capitalize; }
  
  .about-us-statement {
    background: #fff;
    display: inline-block;
    font-size: 25px;
    padding: 15px; }
  
  .about-us {
    background: #f9b919;
    text-align: center; 
    padding: 1px 0px 1px 0px;
  }
  
  .our-goal {
    font-size: 25px;
    text-transform: capitalize;
    padding: 15px; }
  
  .our-goal-first-statement {
    font-size: 35px; }
  
  .background-image, .background-image-two, .background-image-three, .background-image-four {
    background-image: url("../../assets/pizzatime.jpg");
    min-height: 280px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media screen and (max-width: 750px) {
      .background-image, .background-image-two, .background-image-three, .background-image-four {
        background-attachment: unset; } }
  
  .background-image {
    min-height: 200px; }
  
  .background-image-two {
    background-image: url("../../assets/hog-heaven-pizza.jpg"); }
  
  .background-image-three {
    background-image: url("../../assets/buff-off-pizza.jpg"); }
  
  .background-image-four {
    background-image: url("../../assets/pizzatime.jpg"); }
  