.footer {
    background-color: #382E2A;
    padding: 25px;
    color: #fff;
    text-align: center; }
  
  .footer-container {
    width: 100%;
    display: inline-block; }
    @media screen and (max-width: 880px) {
      .footer-container {
        width: 95%; } }
  
  .copy-right-statement, .social-links-container, .hours-wrapper {
    display: inline-block;
    width: 30%;
    min-width: 200px;
    margin: 5px;
    font-size: 15px; }
  
  .social-links {
    margin: 10px;
    cursor: pointer;
    height: 30px; }
  
  .hours-wrapper {
    background-color: #bb6a64;
    padding: 5px;
    border-radius: 5px;
    width: auto; }
  
  .davidpina-hyper-link {
    color: #fff;
    margin: 5px;
    font-size: 8px; }
  