.menu p {
  margin-bottom: 1.25rem;
}

.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}
.menu {
  background: #382e2a;
  padding-bottom: 150px;
}
.title {
  text-align: center;
  margin-bottom: 2rem;
}
.loadingHeader{
  text-align: center;
  color: #fff;
  font-size: 3.5em;
  margin-top: 80px;
}
.underline {
  width: 6rem;
  height: 0.25rem;
  background: #c59d5f;
  margin-left: auto;
  margin-right: auto;
}

.btn-container {
  margin: 4rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.filter-btn {
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color:  #fff;
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 0.25rem;
}

.active {
  border-bottom: 2px solid;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  gap: 3rem 2rem;
  justify-items: start;
}

.menu-item {
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;
}

.photo {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border: 0.25rem solid hsl(45deg 100% 50%);
  border-radius: 0.25rem;
  display: block;
}

.logo {
  object-fit: cover;
  height: auto;
  width: 200px;
  border-radius: 0.25rem;  
}

.item-info header {
  display: flex;
  justify-content: space-between;
  color: #fff;
  border-bottom: 0.5px dotted hsl(210, 22%, 49%);
}
.item-info h4 {
  margin-bottom: 0.5rem;
}
.price {
  color: #c59d5f;
}
.item-text {
  margin-bottom: 0;
  padding-top: 1rem;
  color: #9c9c9c;  
}


@media screen and (min-width: 768px) {
  .menu-item {
    grid-template-columns: 225px 1fr;
    gap: 0 1.25rem;
    max-width: 40rem;
  }
  .photo {
    height: 175px;
  }
}
@media screen and (min-width: 1200px) {
  .section-center {
    width: 95vw;
    grid-template-columns: 1fr 1fr;
  }
  .photo {
    height: 150px;
  }
}
